 
<template>
<div> 
  <van-list
    v-model="loading"
    :finished="finished"
    :finished-text="finishedText" 
    offset="100"
    @load="onLoad"
    >
      <van-cell v-for="item in list" :key="item.id" :title="item.title" border is-link @click="goDetail(item)" /> 
 
    </van-list>
</div>
</template>




<script>
import { getVeteransList } from '@/api/veteransApi'
// import { listStoreManage } from '@/api/user'

export default {
  name: 'ActivityList',
  components: {
  },
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: 100
    }
  },
  data() {
    return {
        list: [],
        currentList: [],
        current: 1,
        loading: false,
        finished: false,
        finishedText: ''
    }
  },
  // 计算属性
  computed: {},

  // watch
  watch: {},

  // 创建完成（可以访问当前this实例）
  created() {
    // this.getList()
  },

  // 挂载完成（可以访问DOM元素）
  mounted() {},
  
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  // 方法集合
  methods: { 
    goDetail(val) {
      this.$router.push({path: '/veteransActivityDetail', query:{id: val.id}})
    },
    // getList() {  
    //   let data = { current: this.current, size: '5'}
    //   getVeteransList(data).then(res => {   
    //     this.currentList = res.data.records 
    //     this.list = this.list.concat(res.data.records)  
    //     this.loading = false
    //   })
    // },
    async onLoad() {    
      if (this.currentList !== 0) {  
        const res = await getVeteransList({ current: this.current, size: '5'})   
        this.currentList = res.data.records ? res.data.records.length : 0
        this.list = this.list.concat(res.data.records)
        this.current++ 
        this.loading = false 
      } else {
        this.finished = true; 
        this.finishedText = '没有更多了'
      } 
    },

    // onLoad() { 
    //   if (this.currentList > 0 ) {
    //     this.getList()
    //     this.current++ 
    //   } else {
    //     this.finished = true; 
    //   }
    // },
  }
}
</script>

<style lang='scss'>
</style>
<style lang='scss' scoped>
// @import url(s); 引入公共css类
</style>
